import { getId, OCPPDate } from '../common/help-functions';


const metaDataType = {
  connectorId: 0,
  bootNotification: {},
  RFIDTag: null,
  status: null,
  idTag: null,
  meterStart: null,
  meterStop: null,
  currentMeterValue: null,
  diagnosticStatus: null,
  firmWareStatus: null,
}


/**
 * Send Command to OCPP Central System
 * @param { string } command 
 * @param { metaDataType } metaData
 */
export const sendCommand = (command, metaData) => {
  const id = getId()
  let message

  switch (command) {
    case 'Heartbeat':
      message = {}
      break;
    case 'BootNotification':
      message = metaData.bootNotification
      break;
    case 'Authorize':
      message = { idTag: metaData.RFIDTag }
      break;
    case 'StatusNotification':
      message = {
        connectorId: metaData.connectorId,
        status: metaData.status,
        errorCode: 'NoError',
        info: '',
        timestamp: OCPPDate(new Date()),
        vendorId: '',
        vendorErrorCode: ''
      }
      break;
    case 'StartTransaction':
      message = {
        connectorId: metaData.connectorId,
        idTag: metaData.idTag,
        meterStart: metaData.startMeterValue,
        timestamp: OCPPDate(new Date()),
        // reservationId: ''
      }
      break;
    case 'StopTransaction':
      console.log(metaData)
      message = {
        idTag: metaData.idTag,
        meterStop: metaData.currentMeterValue,
        timestamp: OCPPDate(new Date()),
        transactionId: metaData.transactionId,
        reason: metaData.stopReason,
        // transactionData: ''
      }
      break;
    case 'MeterValues':
      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      let importPower = getRandomInt(50000,60000);
      let Voltage = getRandomInt(400,500);
      message = {
        connectorId: metaData.connectorId,
        transactionId: metaData.transactionId,
        meterValue: [
          {
            timestamp: OCPPDate(new Date()),
            sampledValue: [
              { measurand: 'Voltage', phase: 'L3', unit: 'V', value: Voltage },
              { measurand: 'Current.Import', phase: 'L3', unit: 'A', value: parseInt(importPower/Voltage) },
              { measurand: 'Energy.Active.Import.Register', unit: 'Wh', value: metaData.currentMeterValue.toString() },
              { measurand: 'Power.Active.Import', unit: 'W', value: importPower},
              { measurand: 'Energy.Active.Import.Interval', unit: 'Wh', value: metaData.currentMeterValue.toString() },
              { measurand: 'SoC', unit: '%', value: ((metaData.currentMeterValue/50000*100)<=100)?(metaData.currentMeterValue/50000*100).toFixed(2):100 },
              { measurand: 'Temperature', unit: '℃', value: 40 }
            ]
          }
        ]
      }
      break;
    case 'DiagnosticsStatusNotification':
      message = { status: metaData.diagnosticStatus }
      break;
    case 'FirmwareStatusNotification':
      message = { status: metaData.firmWareStatus }
      break;
    default:
      message = {}
      break;
  }

  return {
    ocppCommand: JSON.stringify([ 2, id, command, message ]),
    lastCommand: { id, command, connector: metaData.connectorId || metaDataType.connectorId }
  }
}